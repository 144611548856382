<app-navbar-one></app-navbar-one>
<div class="saas-home-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container margin-atas-page">
                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <div class="hero-content">
                            <span>I3O Official Event</span>
                            <h1>Indonesia International IoT Olympiad</h1>
                            <!-- <p>
                                At the beginning of the 21st century, the term
                                'internet of things' began to be widely used by
                                the media. Interest in IoT technology has also
                                experienced a significant increase from
                                discussions about short-range wireless
                                communications, sensor networks and so on. Even
                                today, human life cannot be separated from the
                                internet. The use of the internet and also the
                                speed of technological change forces humans to
                                continue to adapt to its development.
                            </p> -->

                            <div class="saas-btn">
                                <a
                                    class="saas-btn-one m-2"
                                    href="https://registration-i3o-2025.vercel.app/"
                                    >Registration Link!!!
                                    <i class="fa fa-arrow-right"></i
                                ></a>
                                <a
                                    class="saas-btn-one m-2"
                                    href="https://drive.google.com/file/d/1a7oq8sKkuSxf9eXQl--WtQXna__adMTn/view?usp=sharing"
                                    target="_blank"
                                    >Guide Book <i class="fa fa-arrow-right"></i
                                ></a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="saas-image">
                            <img src="assets/img/main/iot-bg.png" alt="image" />
                            <img
                                class="banner-tab"
                                src="assets/img/main/animate.png"
                                alt="image"
                            />
                        </div>
                    </div>

                    <div class="circle-img">
                        <img
                            src="assets/img/saas-banner/circle.png"
                            alt="image"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-img1">
        <img src="assets/img/shape/shape1.svg" alt="image" />
    </div>
    <div class="shape-img2">
        <img src="assets/img/shape/shape2.svg" alt="image" />
    </div>
    <div class="shape-img3">
        <img src="assets/img/shape/shape3.png" alt="image" />
    </div>
    <div class="shape-img4">
        <img src="assets/img/shape/shape4.png" alt="image" />
    </div>
    <div class="shape-img5">
        <img src="assets/img/shape/shape6.png" alt="image" />
    </div>
</div>

<!-- categories Start  -->
<br />
<section class="services-section pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="services-area-content">
                    <span>Categories</span>
                    <h3>Event Categories</h3>
                    <p>Join our event and select your categories.</p>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="single-services">
                    <div class="row">
                        <div class="col-lg-6 p-0 col-md-6 col-sm-6">
                            <div class="services-image">
                                <p class="fas fa-briefcase fa-3x"></p>
                                <h3>IoT in Business</h3>
                            </div>
                        </div>
                        <div class="col-lg-6 p-0 col-md-6 col-sm-6">
                            <div class="services-image">
                                <p class="fas fa-chalkboard fa-3x"></p>
                                <h3>IoT in Education</h3>
                            </div>
                        </div>
                        <div class="col-lg-6 p-0 col-md-6 col-sm-6">
                            <div class="services-image">
                                <p class="fas fa-heartbeat fa-3x"></p>
                                <h3>IoT in Healthcare</h3>
                            </div>
                        </div>
                        <div class="col-lg-6 p-0 col-md-6 col-sm-6">
                            <div class="services-image">
                                <p class="fas fa-tractor fa-3x"></p>
                                <h3>IoT in Agriculture</h3>
                            </div>
                        </div>
                        <div class="col-lg-6 p-0 col-md-6 col-sm-6">
                            <div class="services-image">
                                <p class="fas fa-cogs fa-3x"></p>
                                <h3>IoT in Energy and Manufacturing</h3>
                            </div>
                        </div>

                        <div class="col-lg-6 p-0 col-md-6 col-sm-6">
                            <div class="services-image">
                                <p class="fas fa-users fa-3x"></p>
                                <h3>Social Science</h3>
                            </div>
                        </div>
                        <div class="col-lg-6 p-0 col-md-6 col-sm-6">
                            <div class="services-image">
                                <p class="fas fa-lightbulb fa-3x"></p>
                                <h3>Innovative Science</h3>
                            </div>
                        </div>
                        <div class="col-lg-6 p-0 col-md-6 col-sm-6">
                            <div class="services-image">
                                <p class="fas fa-robot fa-3x"></p>
                                <h3>Computer Science and Robotic</h3>
                            </div>
                        </div>
                        <div class="col-lg-6 p-0 col-md-6 col-sm-6">
                            <div class="services-image">
                                <p class="fas fa-book fa-3x"></p>
                                <h3>Education</h3>
                            </div>
                        </div>
                        <div class="col-lg-6 p-0 col-md-6 col-sm-6">
                            <div class="services-image">
                                <p class="fas fa-solar-panel fa-3x"></p>
                                <h3>Physics, Energy and Engineering</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Purpose section  -->
<section class="features-section ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-12">
                <div class="features-image">
                    <img src="assets/img/main/purpose.png" alt="image" />

                    <div class="features-shape">
                        <img
                            src="assets/img/features/shape.png"
                            class="wow fadeInUp"
                            data-wow-delay="0.6s"
                            alt="image"
                        />
                    </div>

                    <div class="features-arrow">
                        <img
                            src="assets/img/features/features-img2.png"
                            class="wow fadeInRight"
                            data-wow-delay="0.6s"
                            alt="image"
                        />
                    </div>
                </div>
            </div>

            <div class="col-lg-5 col-md-12">
                <div class="features-area-content">
                    <span>Purpose</span>
                    <h2>This activity aims to</h2>

                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="features-text bg-f4ffb3">
                                <div class="icon">
                                    <i class="flaticon-brain-and-head"></i>
                                </div>
                                <br />
                                <p>
                                    Become a forum for students to evaluate the
                                    work they have
                                </p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="features-text bg-ffe7dc">
                                <div class="icon">
                                    <i class="flaticon-it"></i>
                                </div>
                                <br />
                                <p>
                                    Increase the ability to develop creative
                                    ideas in the IoT world
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="video-section pb-100">
    <!-- change background in stle.scss -->
    <div class="container">
        <div class="video-content">
            <a
                href="https://www.youtube.com/watch?v=dTqLOVIgqlQ"
                class="video-btn popup-youtube"
                ><i class="fas fa-play"></i
            ></a>
        </div>
    </div>
</div>

<section class="subscribe-section ptb-100">
    <div class="container-fluid pr-0">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="subscribe-area-content">
                    <span>Email address</span>
                    <h3>Subscribe our monthly newsletter to get updated</h3>
                    <p>
                        Don’t be afraid your mail is secure it no will be shared
                        anywhere or everywhere.
                    </p>

                    <form
                        class="newsletter-form"
                        action="https://formspree.io/f/xoqoddrr"
                        method="POST"
                    >
                        <input
                            type="email"
                            class="input-newsletter"
                            placeholder="Enter your email"
                            name="email"
                            required
                        />
                        <button type="submit">
                            Submit now <i class="fa fa-arrow-right"></i>
                        </button>
                    </form>

                    <ul class="social-list">
                        <li class="list-heading">Follow us for update:</li>
                        <li>
                            <a
                                href="https://www.facebook.com/people/Indonesia-Young-scientist-Association/100063979907207/"
                                class="facebook"
                                target="_blank"
                                ><i class="fab fa-facebook-f"></i
                            ></a>
                        </li>
                        <li>
                            <a
                                href="https://www.youtube.com/@IYSAOfficial"
                                class="youtube"
                                target="_blank"
                                ><i class="fab fa-youtube"></i
                            ></a>
                        </li>
                        <li>
                            <a
                                href="https://www.linkedin.com/company/indonesian-young-scientist-association-iysa/"
                                class="linkedin"
                                target="_blank"
                                ><i class="fab fa-linkedin-in"></i
                            ></a>
                        </li>
                        <li>
                            <a
                                href="https://www.instagram.com/iysa_official/?hl=id"
                                class="instagram"
                                target="_blank"
                                ><i class="fab fa-instagram"></i
                            ></a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-6 col-md-12 pr-0">
                <div class="subscribe-image">
                    <div class="main-image">
                        <img
                            src="assets/img/specialty.jpg"
                            class="wow fadeInUp"
                            data-wow-delay="0.7s"
                            alt="image"
                        />
                    </div>

                    <div class="subscribe-mobile-image">
                        <img
                            src="assets/img/subscribe/pic.png"
                            class="wow zoomIn"
                            data-wow-delay="0.7s"
                            alt="image"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="partner-section pb-100">
    <div class="container">
        <h1 class="mb-5">Organized By :</h1>
        <div class="partner-slider owl-carousel owl-theme">
            <div class="partner-item">
                <a target="_blank" class="d-block"
                    ><img
                        class="partner-logo"
                        src="assets/img/partnar/iysa.png"
                        alt="partner"
                /></a>
            </div>

            <div class="partner-item">
                <a target="_blank" class="d-block"
                    ><img
                        class="partner-logo"
                        src="assets/img/partnar/warmadewa.png"
                        alt="partner"
                /></a>
            </div>
        </div>
        <!-- Organized End -->

        <!-- Supported Start -->
        <!-- <h1 class="mt-5 mb-5">Supported By :</h1>
        <div class="partner-slider owl-carousel owl-theme">
            <div class="partner-item">
                <a target="_blank" class="d-block"
                    ><img
                        class="partner-logo"
                        src="assets/img/logo/IFEST.png"
                        alt="partner"
                /></a>
            </div>

            <div class="partner-item">
                <a target="_blank" class="d-block"
                    ><img
                        class="partner-logo"
                        src="assets/img/logo/MIICA.png"
                        alt="partner"
                /></a>
            </div>
            <div class="partner-item">
                <a target="_blank" class="d-block"
                    ><img
                        class="partner-logo"
                        src="assets/img/logo/RED-GLOBAL.png"
                        alt="partner"
                /></a>
            </div>
            <div class="partner-item">
                <a target="_blank" class="d-block"
                    ><img
                        class="partner-logo"
                        src="assets/img/logo/RED-NATIONAL.png"
                        alt="partner"
                /></a>
            </div>
        </div> -->
        <!-- Supported End -->
        <!-- <iframe src="https://www.ospc.or.id/homeregist" width="100%" height="800px"></iframe> -->
    </div>
</div>

<!-- contact start -->

<section class="talk-section pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="talk-form">
                    <form
                        action="https://formspree.io/f/xyyqjyqr"
                        method="POST"
                    >
                        <div class="form-group">
                            <input
                                type="text"
                                name="name"
                                class="form-control"
                                placeholder="What's Your Name"
                                required
                            />
                        </div>

                        <div class="form-group">
                            <input
                                type="email"
                                name="email"
                                class="form-control"
                                placeholder="Your email address here"
                                required
                            />
                        </div>

                        <div class="form-group">
                            <input
                                type="number"
                                name="number"
                                class="form-control"
                                placeholder="number"
                                required
                            />
                        </div>

                        <div class="form-group">
                            <textarea
                                name="message"
                                class="form-control"
                                cols="30"
                                rows="6"
                                placeholder="Write your text here"
                                required
                            ></textarea>
                        </div>

                        <button type="submit" class="btn btn-primary">
                            Submit Now
                        </button>
                    </form>
                </div>

                <div class="talk-image">
                    <div class="talk-shape">
                        <img
                            src="assets/img/talk/talk-img1.png"
                            class="wow fadeInUp"
                            data-wow-delay="0.6s"
                            alt="image"
                        />
                    </div>

                    <div class="talk-arrow">
                        <img
                            src="assets/img/talk/talk-img2.png"
                            class="wow fadeInRight"
                            data-wow-delay="0.6s"
                            alt="image"
                        />
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="talk-content-area">
                    <span>Have any queries?</span>
                    <p>
                        Please feel free to contact us if you need other
                        assistance.
                    </p>

                    <div class="talk-info">
                        <p>if you need emergency somethings</p>
                        <a
                            href="https://api.whatsapp.com/send/?phone=6288213248890&text&type=phone_number&app_absent=0"
                            target="_blank"
                            ><h3>0882 1324 8890</h3></a
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- contact end -->
